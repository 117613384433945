$break-sm: 600px
$break-md: 900px

$black: #232323
$yellow: #FFE66D

*
  margin: 0
  padding: 0

  font-family: Poppins, sans-serif
  font-weight: 200

  background-color: $black
  color: white

a
  text-decoration: none

input, textarea
  @extend h5
  @extend .w-100
  box-sizing: border-box
  padding: 1em
  margin-bottom: 2em

  color: white
  border: 3px solid $yellow
  border-radius: 5px
  outline: 0px solid $yellow
  transition: outline 0.2s ease-out

  &:focus
    outline: 3px solid $yellow

textarea
  min-height: 10em
  max-width: 100%
  min-width: 100%

.label
  margin-bottom: 0.5em

button
  @extend .w-100
  @extend .bg-yellow
  @extend h5
  @extend .c-black
  @extend .bold
  cursor: pointer
  padding: 1em
  border: none
  border-radius: 5px

h1, h2, h3, h4, h5, span, p
  color: inherit
  background: transparent

h1
  font-size: 56px
h2
  font-size: 36px
h3
  font-size: 28px
h4
  font-size: 20px
h5
  font-size: 16px

@media only screen and (max-width: $break-md)
  h1
    font-size: 36px
  h2
    font-size: 28px
  h3
    font-size: 20px
  h4
    font-size: 16px
  h5
    font-size: 12px

.large-screen
  display: block

  @media screen and ( max-width: $break-md )
    display: none

.small-screen
  display: none

  @media screen and ( max-width: $break-md )
    display: block

.w-100
  width: 100%

.w-max
  width: 100%
  max-width: 900px

.h-100
  height: 100vh

.pb-0
  padding-bottom: 0 !important

.mb-1
  margin-bottom: 0.5em !important

.c-black
  color: $black !important

.c-yellow
  color: $yellow !important

.c-white
  color: white !important

.bg-black
  background-color: $black !important

.bg-yellow
  background-color: $yellow !important

.bg-white
  background-color: white !important

.center
  display: flex
  flex-direction: column

  justify-content: center
  align-items: center
  text-align: center

.center-row
  display: flex
  flex-direction: row

  justify-content: center
  align-items: center
  text-align: center

.center-left
  display: flex
  flex-direction: row

  justify-content: left
  align-items: center
  text-align: left

.center-right
  display: flex
  flex-direction: row

  justify-content: right
  align-items: center
  text-align: right

.bold
  font-weight: 700

header, footer
  @extend .center-row
  width: 100%
  height: 60px

  a
    padding: 5px 10px
    margin: 0 100px

    @media only screen and (max-width: $break-md)
      margin: 0 60px

    @media only screen and (max-width: $break-sm)
      margin: 0 20px

  &.bottom
    top: calc(100% - 60px)

header
  top: 0
  position: fixed
  z-index: 999

footer
  overflow: hidden
  padding-bottom: 2em

  img
    height: 2.5em

.header-space
  height: 60px

main
  @extend .center
  padding: 2em

section
  @extend .w-max
  padding: 2em
  text-align: left

  & > *:not(:last-child)
    margin-bottom: 2em

.billboard
  @extend .w-100
  @extend .h-100
  @extend .center
  @extend .c-yellow
  position: relative
  overflow: hidden !important

  &.md
    height: 60vw
    max-height: 75vh

    @media only screen and (min-height: $break-md)
      max-height: 600px !important

  img
    position: absolute
    background: none
    height: 100%

hr
  width: 0px
  height: 1.5px
  background-color: $yellow
  border: none

  &.full
    width: 100% !important

.skill-wrap
  position: relative

  .value
    position: absolute
    top: 0
    right: 0

.bar-wrap
  @extend .center-left
  position: relative
  overflow: hidden !important
  box-sizing: border-box

  width: 100%
  height: 36px

  border: 4px solid $yellow
  border-radius: 2em

  @media only screen and (max-width: $break-md)
    height: 30px

  .fill
    background-color: $yellow
    position: absolute
    height: 100%

.bar
  width: 20%
  height: 100%
  border: 2px solid $yellow
  background: transparent
  z-index: 10

  &:first-child
    border-left: none
    border-top-left-radius: 2em
    border-bottom-left-radius: 2em

  &:nth-child(5)
    border-right: none
    border-top-right-radius: 2em
    border-bottom-right-radius: 2em

.name-line
  fill-rule: evenodd
  stroke: $yellow
  stroke-width: 0.25mm

.education-item, .projects-item
  @extend .center
  padding: 0 1em !important
  margin-bottom: 2em

  h3
    margin-bottom: 5px

  h4
    margin-bottom: 5px

  img
    max-width: calc(min(390px, 80vw))
    max-height: 75vw

.projects-item
  img
    border: 3px solid $yellow
    border-radius: 5px

.projects-wrap
  & >
    margin-top: 2em

.timeline-wrap
  h3
    margin-top: 14px
    margin-bottom: 0.5em

  h4
    margin-bottom: 0.5em

    &:last-of-type
      margin-bottom: 0 !important

  .timeline-padding
    padding-bottom: 2em

  .timeline-dot
    @extend .center
    width: 50px
    height: 50px

    background: none !important
    border: none !important
    box-shadow: none !important

    @media only screen and (max-width: $break-md)
      width: 40px
      height: 40px

    img
      max-width: 50px
      max-height: 40px

      @media only screen and (max-width: $break-md)
        max-width: 40px
        max-height: 30px

  .MuiTimelineConnector-root
    background-color: white !important
