* {
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  font-weight: 200;
  background-color: #232323;
  color: white;
}

a {
  text-decoration: none;
}

input, textarea {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 1em;
  margin-bottom: 2em;
  color: white;
  border: 3px solid #FFE66D;
  border-radius: 5px;
  outline: 0px solid #FFE66D;
  -webkit-transition: outline 0.2s ease-out;
  transition: outline 0.2s ease-out;
}

input:focus, textarea:focus {
  outline: 3px solid #FFE66D;
}

textarea {
  min-height: 10em;
  max-width: 100%;
  min-width: 100%;
}

.label {
  margin-bottom: 0.5em;
}

button {
  cursor: pointer;
  padding: 1em;
  border: none;
  border-radius: 5px;
}

h1, h2, h3, h4, h5, input, textarea, button, span, p {
  color: inherit;
  background: transparent;
}

h1 {
  font-size: 56px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 20px;
}

h5, input, textarea, button {
  font-size: 16px;
}

@media only screen and (max-width: 900px) {
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 16px;
  }
  h5, input, textarea, button {
    font-size: 12px;
  }
}

.large-screen {
  display: block;
}

@media screen and (max-width: 900px) {
  .large-screen {
    display: none;
  }
}

.small-screen {
  display: none;
}

@media screen and (max-width: 900px) {
  .small-screen {
    display: block;
  }
}

.w-100, input, textarea, button, .billboard {
  width: 100%;
}

.w-max, section {
  width: 100%;
  max-width: 900px;
}

.h-100, .billboard {
  height: 100vh;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.5em !important;
}

.c-black, button {
  color: #232323 !important;
}

.c-yellow, .billboard {
  color: #FFE66D !important;
}

.c-white {
  color: white !important;
}

.bg-black {
  background-color: #232323 !important;
}

.bg-yellow, button {
  background-color: #FFE66D !important;
}

.bg-white {
  background-color: white !important;
}

.center, main, .billboard, .education-item, .projects-item, .timeline-wrap .timeline-dot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.center-row, header, footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.center-left, .bar-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: left;
}

.center-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: right;
      -ms-flex-pack: right;
          justify-content: right;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: right;
}

.bold, button {
  font-weight: 700;
}

header, footer {
  width: 100%;
  height: 60px;
}

header a, footer a {
  padding: 5px 10px;
  margin: 0 100px;
}

@media only screen and (max-width: 900px) {
  header a, footer a {
    margin: 0 60px;
  }
}

@media only screen and (max-width: 600px) {
  header a, footer a {
    margin: 0 20px;
  }
}

header.bottom, footer.bottom {
  top: calc(100% - 60px);
}

header {
  top: 0;
  position: fixed;
  z-index: 999;
}

footer {
  overflow: hidden;
  padding-bottom: 2em;
}

footer img {
  height: 2.5em;
}

.header-space {
  height: 60px;
}

main {
  padding: 2em;
}

section {
  padding: 2em;
  text-align: left;
}

section > *:not(:last-child) {
  margin-bottom: 2em;
}

.billboard {
  position: relative;
  overflow: hidden !important;
}

.billboard.md {
  height: 60vw;
  max-height: 75vh;
}

@media only screen and (min-height: 900px) {
  .billboard.md {
    max-height: 600px !important;
  }
}

.billboard img {
  position: absolute;
  background: none;
  height: 100%;
}

hr {
  width: 0px;
  height: 1.5px;
  background-color: #FFE66D;
  border: none;
}

hr.full {
  width: 100% !important;
}

.skill-wrap {
  position: relative;
}

.skill-wrap .value {
  position: absolute;
  top: 0;
  right: 0;
}

.bar-wrap {
  position: relative;
  overflow: hidden !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 36px;
  border: 4px solid #FFE66D;
  border-radius: 2em;
}

@media only screen and (max-width: 900px) {
  .bar-wrap {
    height: 30px;
  }
}

.bar-wrap .fill {
  background-color: #FFE66D;
  position: absolute;
  height: 100%;
}

.bar {
  width: 20%;
  height: 100%;
  border: 2px solid #FFE66D;
  background: transparent;
  z-index: 10;
}

.bar:first-child {
  border-left: none;
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em;
}

.bar:nth-child(5) {
  border-right: none;
  border-top-right-radius: 2em;
  border-bottom-right-radius: 2em;
}

.name-line {
  fill-rule: evenodd;
  stroke: #FFE66D;
  stroke-width: 0.25mm;
}

.education-item, .projects-item {
  padding: 0 1em !important;
  margin-bottom: 2em;
}

.education-item h3, .projects-item h3 {
  margin-bottom: 5px;
}

.education-item h4, .projects-item h4 {
  margin-bottom: 5px;
}

.education-item img, .projects-item img {
  max-width: calc(min(390px, 80vw));
  max-height: 75vw;
}

.projects-item img {
  border: 3px solid #FFE66D;
  border-radius: 5px;
}

.projects-wrap > {
  margin-top: 2em;
}

.timeline-wrap h3 {
  margin-top: 14px;
  margin-bottom: 0.5em;
}

.timeline-wrap h4 {
  margin-bottom: 0.5em;
}

.timeline-wrap h4:last-of-type {
  margin-bottom: 0 !important;
}

.timeline-wrap .timeline-padding {
  padding-bottom: 2em;
}

.timeline-wrap .timeline-dot {
  width: 50px;
  height: 50px;
  background: none !important;
  border: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

@media only screen and (max-width: 900px) {
  .timeline-wrap .timeline-dot {
    width: 40px;
    height: 40px;
  }
}

.timeline-wrap .timeline-dot img {
  max-width: 50px;
  max-height: 40px;
}

@media only screen and (max-width: 900px) {
  .timeline-wrap .timeline-dot img {
    max-width: 40px;
    max-height: 30px;
  }
}

.timeline-wrap .MuiTimelineConnector-root {
  background-color: white !important;
}
